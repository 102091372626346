import React, { useContext, useRef, useEffect } from "react"
import { graphql } from "gatsby"
import PageWrapper from 'components/globals/pageWrapper'

import BlogCarousel from 'components/flexibleContent/blogCarousel'
import TitleCopy from 'components/flexibleContent/titleCopy'
import ParallaxSection from 'components/globals/parallaxSection'

import { MainContext } from "context/context"

export default ({ data }) => {
    const {
        post: {
            title,
            content,
            excerpt,
            date,
            postFields: {
                author,
                heroBackgroundImage,
                headerheroTextColour
            }
        },
        posts
    } = data.siteData

    const elementRef = useRef(null)

    useEffect(() => {
        if(elementRef && elementRef.current && (!headerheroTextColour || headerheroTextColour === 'white')){
            addInvertedHeaderSection(elementRef.current)
        }
    }, [elementRef])

	const {
        addInvertedHeaderSection,
    } = useContext(MainContext)
    
    return (
        <PageWrapper
            title={title} 
            description={excerpt}
        >
            <section 
                className={'blog-hero'}
                ref={ref => elementRef.current = ref}
            >
                <ParallaxSection
                    backgroundUrl={heroBackgroundImage ? heroBackgroundImage.mediaItemUrl : ''}
                >
                    <div className={'container'}>
                        <h1 style={{
                            color: headerheroTextColour
                        }}>
                            { title }
                        </h1>
                        <aside>
                            <h2>
                                { author.name }
                            </h2>
                            <div className={'copy medium'}>
                                { author.role }
                            </div>
                        </aside>
                    </div>
                </ParallaxSection>
            </section>
            <section className={'blog-content container'}>
                <aside>
                    <h2>
                        { author.name }
                    </h2>
                    <div className={'copy medium'}>
                        { author.role }
                    </div>
                </aside>
                <main>
                    <div className={'copy medium'} dangerouslySetInnerHTML={{ __html: content }} />
                </main>
            </section>
            <section className={'TitleCopy scrolled-into-view'}>
                <TitleCopy
                    title={'Real stories'}
                    copy={`<p>Things happen pretty quickly around here.<br>Learn how our team make things happen.</p>`}
                />
            </section>
            <section className={'BlogCarousel scrolled-into-view'}>
                <BlogCarousel />
            </section>
        </PageWrapper>
    )
}


export const query = graphql`
  query($databaseId: ID!) {
    siteData {
      post(id: $databaseId, idType: DATABASE_ID) {
        id
		title
		slug
        date
        link
        content(format: RENDERED)
        categories {
          edges {
            node {
              name
              slug
            }
          }
        }
        postFields {
            author {
                name
                role
            }
            heroBackgroundImage {
                mediaItemUrl
            }
            headerheroTextColour
        }
        excerpt(format: RENDERED)
        featuredImage {
          altText
          title(format: RENDERED)
          mediaItemUrl
          slug
		}
      }
      posts {
        edges {
          node {
            id
            link
			title
			slug
            excerpt
            date
            featuredImage {
              mediaItemUrl
            }
            categories {
              nodes {
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`
